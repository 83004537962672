import Axios from 'axios';

const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const register = function(data) {
  return new Promise((resolve, reject) => {
    instance.post('/api/user', data)
      .then(response => {
        if (response.status == 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

export const getProfile = function() {
  return new Promise((resolve, reject) => {
    instance.get('/api/user/me')
      .then(response => {
        if (response.status == 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

export const updateProfile = function(data) {
  return new Promise((resolve, reject) => {
    instance.put('/api/user/me', data)
      .then(response => {
        if (response.status == 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

export default {
  register,
  getProfile,
  updateProfile,
};
