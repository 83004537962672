import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { HeartFilled, ShareAltOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ClassNames from 'classnames';
import Numeral from 'numeral';

const { Meta } = Card;

class LandCard extends React.Component {
  handleOnLike = event => {
    event.preventDefault();
    const { id, onLike } = this.props;
    onLike(id);
  };

  render() {
    const { id, slug, name, level, location, likes, photograph } = this.props;
    const landCardClassName = ClassNames('land-card', {
      'land-protected': level == 'conserved',
      'land-proposed': ['basic', 'pledge'].includes(level),
    });
    return (
      <Card
        className={landCardClassName}
        bordered={false}
        cover={
          <Link to={`/land/${slug}`}>
            <img src={photograph || '/images/no-land-image.jpg'} />
          </Link>
        }
        actions={[
          <div key="1" className="text-left">
            {level != 'conserved' && (
              <a href="#" onClick={this.handleOnLike}>
                <HeartFilled />
                <span className="m-l-5">{Numeral(likes).format('0,0')}</span>
              </a>
            )}
          </div>,
          <div key="2" className="text-right">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                window.location.origin + '/land/' + slug
              )}`}
            >
              <ShareAltOutlined />
            </a>
          </div>,
        ]}
      >
        <Link to={`/land/${slug}`}>
          <Meta title={name} description={location || 'No definido'} />
        </Link>
      </Card>
    );
  }
}

LandCard.defaultProps = {
  id: 0,
  name: '',
  photograph: '',
  level: '',
  location: '',
  likes: 0,
  onLike: () => {},
};

LandCard.propTypes = {
  id: PropTypes.number,
  slug: PropTypes.string,
  name: PropTypes.string,
  photograph: PropTypes.string,
  level: PropTypes.string,
  location: PropTypes.string,
  likes: PropTypes.number,
  onLike: PropTypes.func,
};

export default LandCard;
