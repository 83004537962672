import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { notification } from 'antd';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Moment from 'moment';
import Numeral from 'numeral';
import Button from '../ui/Button';
import Icon from '../ui/Icon';
import ToolBar from './Toolbar';
import LandApi from '../../api/land';
import SocialShareModal from '../land/modals/SocialShareModal';
import Popup from './Popup';

/* eslint-disable-next-line no-undef */
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
let map = null;

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: -66.45,
      lat: 18.2,
      zoom: 8,
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      mostrar: true,
      shareOpen: false,
      shareLink: '',
    };
    this.popupRef = React.createRef();
    this.popupRef.current = new mapboxgl.Popup();
  }

  handleOnShareLink = (slug) => {
    this.setState({
      shareOpen: true,
      shareLink: `https://mapa-33.com/land/${slug}`,
    });
  }

  handleOnLike = (id) => {
    const self = this;
    LandApi.like(id)
      .then(() => {
        notification.success({
          message: '¡Gracias por unirte a la meta común!',
          description:
            'Ahora pendiente a tu correo electrónico para que sigas y conozcas la actualización del proceso de esta propuesta.',
        });
      })
      .catch(err => {
        if (err.status == 401) {
          notification.error({
            message: 'Error',
            description: 'Necesitas registrarte para apoyar la propuesta.',
          });
        } else {
          notification.error({
            message: 'Error',
            description:
              'No se logró registrar tu apoyo. Por favor intenta nuevamente.',
          });
        }
      });
  }

  componentDidMount() {
    map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/rocaiguina/ck81mam531w901ippbvie6wrv',
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    map.on('load', () => {
      map.addSource('protected_areas', {
        type: 'vector',
        url: 'mapbox://rocaiguina.d6j1xz6t',
      });

      map.addSource('lots', {
        type: 'geojson',
        data: this.state.data,
      });

      map.addLayer(
        {
          id: 'protected_areas',
          type: 'fill',
          source: 'protected_areas',
          'source-layer': 'lands-380u2f',
          layout: {
            visibility: 'visible',
          },
          paint: { 'fill-color': '#5bbcae' },
        },
        'waterway-label'
      );

      map.addLayer(
        {
          id: 'lots',
          type: 'fill',
          source: 'lots',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-color': '#E36D9D',
          },
        },
        'waterway-label'
      );

      fetch(`${process.env.REACT_APP_API_URL}/api/land/geojson?area=proposed`)
        .then(response => response.json())
        .then(data => {
          map.getSource('lots').setData(data[0].geojson);
        })
        .catch(error => {
          console.error(error);
        });

      map.on('click', 'protected_areas', e => {
        const land = e.features[0].properties;

        LandApi.get(land.id)
          .then(response => {
            const popupNode = document.createElement("div")
            ReactDOM.render(
              <Popup
                id={response.id}
                name={response.name}
                slug={response.slug}
                photographURL={response.photographURL}
                level={response.level}
                likes={response.likes}
                createdAt={response.createdAt}
                onShare={this.handleOnShareLink}
                onLike={this.handleOnLike}
              />,
              popupNode
            );
            this.popupRef.current
              .setLngLat(e.lngLat)
              .setDOMContent(popupNode)
              .addTo(map);
          })
          .catch((err) => {
            console.log(err);
            notification.error({
              message: 'Error',
              description:
                'No se logró recuperar la información. Por favor intenta nuevamente.',
            });
          });
      });

      map.on('click', 'lots', e => {
        const land = e.features[0].properties;
        const popupNode = document.createElement("div")
        ReactDOM.render(
          <Popup
            id={land.id}
            name={land.name}
            slug={land.slug}
            photographURL={land.photographURL}
            level={land.level}
            likes={land.likes}
            createdAt={land.createdAt}
            onShare={this.handleOnShareLink}
            onLike={this.handleOnLike}
          />,
          popupNode
        );
        this.popupRef.current
          .setLngLat(e.lngLat)
          .setDOMContent(popupNode)
          .addTo(map);
      });

      map.on('mousemove', e => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['lots', 'protected_areas'],
        });
        if (features.length) {
          map.getCanvas().style.cursor = 'pointer';
        } else {
          map.getCanvas().style.cursor = '';
        }
      });

      // Fix: We need to resize map because the container offsetHeight.
      map.resize();
    });
  }

  componentWillUnmount() {
    map.remove();
  }

  componentDidUpdate(prevProps) {
    if (this.props.areaView !== prevProps.areaView) {
      const areaView = this.props.areaView;
      map.setLayoutProperty(
        'protected_areas',
        'visibility',
        areaView == 'conserved' || areaView == '' ? 'visible' : 'none'
      );
      map.setLayoutProperty(
        'lots',
        'visibility',
        areaView == 'proposed' || areaView == '' ? 'visible' : 'none'
      );
    }
  }

  handleOnZoomIn = () => {
    map.setZoom(map.getZoom() + 0.5);
  };

  handleOnZoomOut = () => {
    if (map.getZoom() > 8) {
      map.setZoom(map.getZoom() - 0.5);
    }
  };

  closeOverlay = () => {
    this.setState({
      mostrar: false,
    });
  };

  handleOnCloseShareModal = () => {
    this.setState({
      shareOpen: false,
    });
  };

  render() {
    return (
      <div className="mapbox-wrapper">
        <div
          id="map_guide"
          ref={el => (this.mapContainer = el)}
          className="mapbox-view"
        />
        <div
          className="toolbar toolbar-left hidden-sm hidden-xs"
          style={{ zIndex: 99 }}
        >
          <ul>
            <li>
              <Button
                size="large"
                shape="round"
                className="ant-btn-dark"
                onClick={this.handleOnZoomIn}
              >
                <Icon type="plus" />
              </Button>
            </li>
            <li>
              <Button
                size="large"
                shape="round"
                className="ant-btn-dark"
                onClick={this.handleOnZoomOut}
              >
                <Icon type="less" />
              </Button>
            </li>
          </ul>
        </div>
        <ToolBar
          area={this.props.areaView}
          mode={this.props.modeView}
          onChangeMode={this.props.onChangeMode}
          onChangeArea={this.props.onChangeArea}
        />
        <SocialShareModal
          link={this.state.shareLink}
          onClose={this.handleOnCloseShareModal}
          visible={this.state.shareOpen}
        />
      </div>
    );
  }
}

Map.propTypes = {
  areaView: PropTypes.string,
  modeView: PropTypes.string,
  onChangeMode: PropTypes.func,
  onChangeArea: PropTypes.func,
};

export default Map;
