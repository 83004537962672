import React from 'react';
import PropTypes from 'prop-types';
import { Input, Typography } from 'antd';

import BaseLayout from '../../layout/base';
import BottomNavigator from '../BottomNavigator';
import TopNavigator from '../TopNavigator';
import Progress from '../Progress';

const { Text } = Typography;
const { TextArea } = Input;

class ActivityToProtectStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  handleOnNext = () => {
    if (this.props.activity_to_protect_this_land) {
      this.props.next();
    } else {
      this.setState({
        errors: { activity_to_protect_this_land: 'Campo requerido' },
      });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <BaseLayout
        title="FORMULARIO DE PROPUESTA"
        onClose={this.props.onClose}
        footerXs={[14, 0, 10]}
        showCloseBtn={true}
        footerRightComponent={
          <Progress onNext={this.handleOnNext} step={16} steps={18} />
        }
      >
        <div className="main-content">
          <TopNavigator previous={this.props.previous} step={16} steps={18} />
          <h2 className="text-center">
            ¿Qué se ha hecho o se está haciendo para proteger este espacio?
          </h2>
          <TextArea
            name="activity_to_protect_this_land"
            className="form-control"
            onChange={this.props.handleChange}
            value={this.props.activity_to_protect_this_land}
            maxLength="600"
            rows="4"
          />
          {errors.activity_to_protect_this_land && (
            <Text type="danger">{errors.activity_to_protect_this_land}</Text>
          )}
          <BottomNavigator
            onPrevious={this.props.previous}
            onNext={this.handleOnNext}
          />
        </div>
      </BaseLayout>
    );
  }
}

ActivityToProtectStep.propTypes = {
  activity_to_protect_this_land: PropTypes.string,
  next: PropTypes.func,
  previous: PropTypes.func,
  handleChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default ActivityToProtectStep;
