import React, { Component, useCallback } from 'react';
import Moment from 'moment';
import Numeral from 'numeral';
import PropTypes from 'prop-types';
import { HeartFilled, ShareAltOutlined } from '@ant-design/icons';

const Popup = function ({
  id,
  name,
  slug,
  photographURL,
  level,
  likes,
  createdAt,
  onLike,
  onShare,
}) {
  const handleOnLike = useCallback(() => {
    if (id != null && onLike != null) {
      onLike(id);
    }
  }, [id, onLike]);

  const handleOnShare = useCallback(() => {
    if (slug != null && onShare != null) {
      onShare(slug);
    }
  }, [onShare, slug]);

  return (
    <div className="mapbox-land-popup">
      <div className="land-photo">
        <a
          href={`/land/${slug}`}
          style={{
            backgroundImage:
              `url('${photographURL || '/images/no-land-image.jpg'}')`
          }}
        ></a>
      </div>
      <div className="land-details">
        <h4 className="land-title">
          <a href={`/land/${slug}`}>{name}</a>
        </h4>
        <div className="separator"></div>
        {level !== 'conserved' ? (
          <p className="land-date">Propuesto: {Moment(createdAt, 'YYYY-MM-DD').format('MM.DD.YYYY')}</p>
        ) : (
          <p>Área protegida.</p>
        )}
        <p className="land-likes land-actions">
          <a href="#" onClick={handleOnLike}>
            <HeartFilled />
            {Numeral(likes).format('0,0')}
          </a>          
          <a href="#" className="land-share" onClick={handleOnShare}>
            <ShareAltOutlined />
          </a>
        </p>
      </div>
    </div>
  );
};

Popup.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  photographURL: PropTypes.string,
  level: PropTypes.string,
  likes: PropTypes.number,
  createdAt: PropTypes.string,
  onLike: PropTypes.func,
  onShare: PropTypes.func,
};

export default Popup;
