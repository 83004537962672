import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';

import { LAND_STATUS_NEW, LAND_STATUS_APPROVED } from '../../constants';

const ProposedLandActivityItem = props => {
  const { id, name, status, createdAt, onDelete } = props;

  const handleOnDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <div className="activity-item activity-proposedland-item">
      <Row gutter={16}>
        <Col xs={14}>
          <Link to={`/land/${id}`} className="activity-title">
            {name}
          </Link>
        </Col>
        <Col xs={10} className="activity-controls">
          {status === LAND_STATUS_NEW && (
            <span className="ant-btn ant-btn-round ant-btn-sm ant-btn-link ant-btn-gray">
              Bajo revisión
            </span>
          )}
          {status === LAND_STATUS_APPROVED && (
            <span className="ant-btn ant-btn-round ant-btn-sm ant-btn-background-ghost ant-btn-turquoise">
              Publicada
            </span>
          )}
          <Link
            to={`/land/${id}/edit`}
            className="ant-btn ant-btn-purple ant-btn-round ant-btn-sm ant-btn-background-ghost"
          >
            Editar
          </Link>
          <Button
            ghost
            shape="circle"
            size="small"
            icon={<CloseOutlined />}
            onClick={handleOnDelete}
          />
        </Col>
      </Row>
    </div>
  );
};

ProposedLandActivityItem.defaultProps = {
  id: 0,
  name: '',
  status: '',
  createdAt: '',
  onDelete: () => {},
};

ProposedLandActivityItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  status: PropTypes.string,
  createdAt: PropTypes.string,
  onDelete: PropTypes.func,
};

export default ProposedLandActivityItem;
