import Axios from 'axios';

const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const login = function(data) {
  return new Promise((resolve, reject) => {
    instance.post('/api/auth/login', data)
      .then(response => {
        if (response.status == 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

export const logout = function() {
  return new Promise((resolve, reject) => {
    instance.get('/api/auth/logout')
      .then(response => {
        if (response.status == 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

export const forgotPassword = function(data) {
  return new Promise((resolve, reject) => {
    instance.post('/api/auth/forgot-password', data)
      .then(response => {
        if (response.status == 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

export const resetPassword = function(token, data) {
  return new Promise((resolve, reject) => {
    instance.post('/api/auth/reset-password/' + token, data)
      .then(response => {
        if (response.status == 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

export default {
  login,
  logout,
  forgotPassword,
  resetPassword,
};
