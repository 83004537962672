import React from 'react';
import PropTypes from 'prop-types';
import { Col, Checkbox, Row, Typography } from 'antd';

import BaseLayout from '../../layout/base';
import BottomNavigator from '../BottomNavigator';
import TopNavigator from '../TopNavigator';
import Progress from '../Progress';
import { LAND_PROPOSED_USES } from '../../../constants';

const { Text } = Typography;

class WhichUseStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  handleOnNext = () => {
    if (
      Array.isArray(this.props.which_uses) &&
      this.props.which_uses.length > 0
    ) {
      this.props.next();
    } else {
      this.setState({
        errors: { which_uses: 'Campo requerido' },
      });
    }
  };

  handleOnChange = checkedValue => {
    const { setFieldValue } = this.props;
    setFieldValue('which_uses', checkedValue);
  };

  render() {
    const { errors } = this.state;
    return (
      <BaseLayout
        title="FORMULARIO DE PROPUESTA"
        onClose={this.props.onClose}
        footerXs={[14, 0, 10]}
        showCloseBtn={true}
        footerRightComponent={
          <Progress onNext={this.handleOnNext} step={15} steps={18} />
        }
      >
        <div className="main-content m-t-20">
          <TopNavigator previous={this.props.previous} step={15} steps={18} />
          <Row gutter={30}>
            <Col
              md={12}
              style={{
                textAlign: 'center',
              }}
            >
              <h2>¿Cuáles usos propones para el terreno?</h2>
              {errors.which_uses && (
                <Text type="danger">{errors.which_uses}</Text>
              )}
              <br />
              <p className="text-left text-muted">
                <strong>Agroecología</strong> - enfoque holístico e integrado que aplica simultáneamente conceptos y principios ecológicos y sociales al diseño y la gestión de sistemas agrícolas y alimentarios sostenibles.
                <br />
                <strong>Bienestar, usos espirituales</strong> - espacio para meditar y buscar tranquilidad. 
                <br />
                <strong>Conservación estricta</strong> - busca proteger ecosistemas que tienen rasgos extraordinarios que se destruirían si se ven sometidos a cualquier impacto humano significativo. 
                <br />
                <strong>Conservación</strong> - protección, cuidado, manejo y mantenimiento de los ecosistemas, hábitats, especies y poblaciones de vida silvestre, dentro o fuera de sus ambientes naturales, con el fin de salvaguardar las condiciones naturales para su permanencia a largo plazo.
                <br />
                <strong>Ecoturismo</strong> - viajes, visitas y actividades a áreas naturales que conservan los ecosistemas y aportan al bienestar de la comunidad local. 
                <br />
                <strong>Educación e investigación científica</strong> - investigación llevada a cabo con el propósito de contribuir a la ciencia a través de la colección de datos, su análisis e interpretación.
                <br />
                <strong>Monitoreo ambiental</strong> - recolección y análisis de observaciones o mediciones repetidas con el fin de evaluar los cambios en las condiciones de un lugar  y/o una especie para el beneficio de un estudio u otro objetivo. 
                <br />
                <strong>Recreación</strong> - Actividades que se llevan a cabo para contribuir a la salud física y mental, como el ejercicio, el juego, el diálogo, etc.
              </p>
              <br />
            </Col>
            <Col
              md={12}
              style={{
                textAlign: 'left',
              }}
            >
              <Checkbox.Group
                style={{ width: '100%' }}
                value={this.props.which_uses}
                onChange={this.handleOnChange}
              >
                <Row>
                  {LAND_PROPOSED_USES.map(item => (
                    <Col key={item.value} span={24}>
                      <Checkbox
                        value={item.value}
                        className="inputprop radiobutton"
                      >
                        {item.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>
          <BottomNavigator
            onPrevious={this.props.previous}
            onNext={this.handleOnNext}
          />
        </div>
      </BaseLayout>
    );
  }
}

WhichUseStep.propTypes = {
  which_uses: PropTypes.array,
  next: PropTypes.func,
  previous: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  onClose: PropTypes.func,
};

export default WhichUseStep;
